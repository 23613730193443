<template>
	<div class="texteditable-wrapper" :style="cStyles" title="Double click to edit">
		<div class="slot" v-if="!edit" @dblclick="editValue()">
			<slot></slot> <i class="edit-icon mdi mdi-pencil"></i>
		</div>
		<div v-else>
			<div class="editable-wrapper input-group input-group-sm">
				<div class="input-group-prepend" v-if="prepend">
					<label class="input-group-text input-sm" for="inputGroupSelect01">{{ prepend }}</label>
				</div>
				<input type="text" class="form-control form-control-sm" ref="input" v-model="text" @keyup.enter="submitNewValue()">
				<div class="input-group-append">
					<button class="btn btn-primary btn-sm" type="button" @click="submitNewValue()">save</button>
				</div>
			</div>
			<div class="content-fill"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TextEditable',
	props: [ 'editable', 'styles', 'prepend' ],
	data: () => {
		return {
			edit: false,
			text: ''
		}
	},
	computed: {
		cStyles() {
			return {
				...(this.styles || {}),
				'z-index': this.edit ? 3 : 1
			}
		}
	},
	methods: {
		editValue() {
			this.text = this.editable
			this.edit = true
			this.$nextTick(() => {
				this.$refs.input.focus()
			});
		},
		submitNewValue() {
			this.edit = false
			this.$emit('update:editable', this.text)
		}
	}
}
</script>

<style scoped>
.edit-icon {
	font-size: 80%;
	line-height: inherit;
}
.texteditable-wrapper {
	position: relative;
}
.editable-wrapper {
	position: absolute;
	z-index: 1;
	padding: 10px 12px;
    background-color: #fff;
    border-radius: 4px;
	top: -10px;
	left: -12px;
	width: calc(100% + 24px);
}
.content-fill {
	width: 1px;
	height: 28px;
}
.slot {
	cursor: text;
}
</style>