<template>
	<div class="mt-0 pt-0">
        <new-link @created="newItemCreated()"/>

        <div class="row mt-2">
            <div class="col-lg-6">
                <div class="input-group">
                    <input class="form-control" type="text" v-model="form.search" placeholder="Search links by a name or an original link">
                    <div class="input-group-append">
                        <button class="btn btn-outline-input" type="button" @click=" form.search = '' ">X</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <select class="form-control" v-model="form.orderBy">
                        <option value="stats_byday.0.hits">By hits</option>
                        <option value="stats_byday.0.users">By users</option>
                        <option value="name">By name</option>
                        <option value="created_at">By date</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <date-range-form :range="form.dates" @input="form.dates = $event" />
                </div>
            </div>
            <div class="col-lg-1">
                <div class="form-group text-right">
                    <button class="btn btn-outline-input btn-block" @click="data()" v-if="!loading"><i class="mdi mdi-reload"></i></button>
                    <button class="btn btn-outline-input btn-block" type="button" disabled v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only"></span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <transition-group  name="flip-list" tag="div" v-if="orderedData && orderedData.length">
                <div class="card link-card" v-for="(item, i) in orderedData" :key="item.id">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-10" >
                                <h4 class="font-weight-normal mt-0">
                                    <small class="mr-2 text-muted" style="display:inline-block">{{ format_date(item.created_at) }}</small>

                                    <span class="mr-2" v-if="item.name">{{ item.name }}</span>

                                    <button class="btn-xs btn btn-outline-primary btn-rounded mr-1" @click="$refs.edit_link.openModal(item)"> 
                                        <i class="mdi mdi-pencil"></i> edit 
                                    </button> 
                                </h4>
                                <div class="mb-1">
                                    <span class="mr-1">{{ item.redirect_link }}</span> 
                                    <i class="mdi mdi-arrow-right mr-1"></i> 
                                    <span class="mr-2">{{ REDIRECT_DOMAIN }}/<b>{{ item.short_link }}</b></span>
                                    <button class="btn-xs btn btn-outline-secondary btn-rounded mr-1" @click="copyTextToClipboard(`${REDIRECT_DOMAIN}/${item.short_link}`)"> copy </button> 
                                </div>
                                <p class="mb-0" v-if="item.tags.length">
                                    <span class="badge badge-light mr-1" v-for="tag in item.tags" :key="tag.tag.id">#{{ tag.tag.name }}</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <h1 class="">
                                    <small class="d-lg-none">Total users/hits</small>
                                    <span class="float-right">
                                        {{ formatAggregate(item, 'stats_byday', '0', 'users') }}
                                        <small title="total clicks">/ {{ formatAggregate(item, 'stats_byday', '0', 'hits') }}</small>
                                    </span>
                                </h1>
                            </div>
                            
                        </div> <!-- end row-->
                    </div> <!-- end card-body -->
                </div>
            </transition-group>
            <div class="card border-secondary border mb-0" v-else>
                <div class="card-body">
                    <h5 class="card-title">No links are added yet</h5>
                    <p class="card-text">Please paste a link in a form above.</p>
                </div>
            </div>
        </div>
        <div style="overflow:hidden" v-else>
            <loader-line />
        </div>
        

        <edit-link-modal ref="edit_link" @update:form="editItem($event)" @remove:form="removeItem($event)"/>
	</div>
</template>

<script>
import EditLinkModal from '@/components/modal/EditLink.vue'
import TextEditable from '@/components/editable/TextEditable.vue'
import NewLink from '@/components/links/NewLinkForm'
import LoaderLine from '@/components/util/LoaderLine'
import dayjs from '@/plugins/day'
import { copyTextToClipboard } from '@/plugins/utils'
import { getNested } from '@/plugins/utils'

import DateRangeForm from '@/components/form/DateRange.vue'

import { getLinksWithStats, getLinkByPKWithStats } from '@/plugins/hasura/queries'

export default {
    name: 'Links',
    data: () => {
        return {
            loading: false,
            items: null,
            REDIRECT_DOMAIN: process.env.VUE_APP_REDIRECT_URL,
            form: {
                search: '',
                dates: {
                    startDate: dayjs().startOf('day'),
                    endDate: dayjs().endOf('day')
                },
                orderBy: 'stats_byday.0.users'
            }
        }
    },
    computed: {
        filteredData() {
            if(!this.form.search) return this.items 

            let search = this.form.search.toLowerCase()
            return this.items.filter(el => {
                return  (el.name && el.name.toLowerCase().indexOf(search) !== -1) 
                    ||  (el.redirect_link && el.redirect_link.toLowerCase().indexOf(search) !== -1)
            })
        },
        orderedData() {
            let data = this.filteredData || []
            let path = this.form.orderBy.split('.')

            data.sort((a, b) => {
                let a_value = getNested(a, ...path) || null
                let b_value = getNested(b, ...path) || null

                if(b_value == a_value) return 0
                return b_value > a_value ? 1 : -1
            })

            return data
        },
        campaign() {
            return this.$route.params.campaign
        }
    },
    async beforeMount() {
		await this.data()
    },
    methods: {
        async data() {
            this.loading = true

            var [err, response] = await this.$orm.queryRAW({
                query: getLinksWithStats,
                variables: {
                    startDate: this.form.dates.startDate,
                    endDate: this.form.dates.endDate,
                    campaign: this.campaign
                }
            })
            
            setTimeout(() => {
                this.loading = false
            }, 250)
            
            if(err) {
                console.error('/views/links', 'methods.data', '$orm.queryAndSubscribe', err)
                return $.NotificationApp.send("Error", "Can't get links", "top-right", "", "error")
            }
            
            console.debug('/views/links', 'methods.data', '$orm.queryAndSubscribe', response.links)
            this.items = response.links
        },
        async editItem(data = {}) {
            if(!data.id) return;
            this.loading = true

            var [err, response] = await this.$orm.queryRAW({
                query: getLinkByPKWithStats,
                variables: {
                    startDate: this.form.dates.startDate,
                    endDate: this.form.dates.endDate,
                    id: data.id
                }
            })
            
            setTimeout(() => {
                this.loading = false
            }, 450)
            if(err) return console.error('/views/links', 'methods.data', '$orm.queryAndSubscribe', err)

            let i = this.items.findIndex((el) => el.id == data.id)
            this.$set(this.items, i, response.links_by_pk)
        },
        removeItem(data = {}) {
            if(!data.id) return;
            let i = this.items.findIndex((el) => el.id == data.id)
            this.items.splice(i, 1);
        },
        async newItemCreated() {
            await this.data()
            this.form.orderBy = 'created_at'
        },
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
        },
        formatAggregate(item, ...path) {
            return getNested(item, ...path) || 0
        },
        copyTextToClipboard
    },
    watch: {
        'form.dates'() {
            if(this.form.dates.endDate.toString() == this.form.dates.startDate.toString()) {
                this.form.dates.endDate = dayjs(this.form.dates.endDate).add(1, 'day')
            }
            this.data()
        },
        '$route.params.campaign'() {
            this.data()
        }
    },
    components: {
        NewLink,
        TextEditable,
        EditLinkModal,
        DateRangeForm,
        LoaderLine
    }
}
</script>

<style>
.link-card {
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(154,161,171,.15);
}
.link-card .card-body {
    padding: 1rem 1.5rem;
}
</style>