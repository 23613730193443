<template>
	<div :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<div class="form-group">
						<label>Link name </label>
						<input class="form-control" type="text" placeholder="Enter a name" v-model="form.name" @keyup.enter="submitForm()" />
					</div>
					<div class="form-group">
						<label>Original url</label>
						<div class="form-group">
							<input type="text" disabled class="form-control" v-model="form.redirect_link">
						</div>
					</div>
					<div class="form-group">
						<label>Custom short part</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">{{ REDIRECT_DOMAIN }}/</span>
							</div>
							<input type="text" class="form-control" placeholder="Short part" v-model="form.short_link" @keyup.enter="submitForm()">
						</div>
					</div>
					<div class="form-group">
						<label>Select tags</label>
						<select class="select2 form-control select2-multiple" id="tags_select" data-toggle="select2" multiple="multiple" data-placeholder="Choose ...">
							<option v-for="tag in tags" :key="tag.id" :value="tag.id">{{ tag.name }}</option>
						</select>
					</div>
				</div>
				<div class="modal-footer">
					<div>
						<button type="button" class="btn btn-danger pull-left" @click="deleteItem()">Delete</button>
					</div>
					<div>	
						<button type="button" class="btn btn-light" data-dismiss="modal">Close</button>

						<button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()">
							Submit
						</button>
						<button v-else class="btn btn-primary" type="button" disabled>
							<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
							Loading...
						</button>
					</div>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	<!-- /.modal -->
</template>

<script>
export default {
    name: 'EditLinkModal',
	data: () => {
		return {
			hashtag: 'edit-link-modal',
			cForm: {
				id: null,
				name: '',
				short_link: '',
				redirect_link: ''
			},
			form: {},
			tags: null,
			base_tags: [],
			loading: false,
            REDIRECT_DOMAIN: process.env.VUE_APP_REDIRECT_URL
		};
	},
	mounted() {
		this.resetForm();
	},
	methods: {
        async openModal(data) {
			this.form.id = data.id 
			this.form.name = data.name 
			this.form.short_link = data.short_link
			this.form.redirect_link = data.redirect_link 

			let selected_tags = data.tags.length ? data.tags.map(el => ''+el.tag.id) : []

			await this.getTags()
			$('#tags_select').select2();
			$('#tags_select').val(selected_tags).trigger('change')
			this.base_tags = selected_tags

            $(`#${this.hashtag}`).modal('show');
        },
        closeModal() {
			$(`#${this.hashtag}`).modal('hide');
		},
		resetForm() {
			this.form = Object.assign({}, this.cForm);
		},
		async submitForm() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.short_link)
				return $.NotificationApp.send('Error', 'Please enter short half', 'top-right', '', 'error');

			this.loading = true;
			
			//editing base
			var [err, result] = await this.$orm.mutate({
                    links: {
                        update: {
                            where: { 
								id: { _eq: this.form.id }
							},
                            _set: { 
								name: this.form.name,
								short_link: this.form.short_link
							}
                        }
                    }
                })

			if (err) {
				this.loading = false;
				console.error('edit-link-modal', 'methods.submitForm', '$http.request1', err);
				if(err.message.indexOf('Uniqueness violation') !== -1) {
					return $.NotificationApp.send('Error', 'This short half is already occupied', 'top-right', '', 'error');
				}

				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}

			//editing tags
			let current_tags = $('#tags_select').val()
			let toDelete = this.base_tags.filter(x => !current_tags.includes(x));
			let toInsert = current_tags.filter(x => !this.base_tags.includes(x));

			if(toDelete.length || toInsert.length) {
				let gqlRequest = {
					links2tags: {}
				}
				if(toInsert.length)
					gqlRequest.links2tags.insert = {
						objects: toInsert.map((tag_id) => {  return {tag_id: +tag_id, link_id: +this.form.id} })
					}
				if(toDelete.length)
					gqlRequest.links2tags.delete = {
						where: {
							_and: {
								link_id: {
									_eq: +this.form.id
								}, 
								tag_id: {
									_in: toDelete
								}
							}
						}
					}

				var [err, result] = await this.$orm.mutate(gqlRequest)
				if (err) {
					this.loading = false;
					console.error('edit-link-modal', 'methods.submitForm', '$http.request2', err);
					return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
				}
			}

			this.loading = false;
			this.$emit('update:form', this.form)

			this.resetForm();
            this.closeModal();
		},
		async getTags() {
			var [err, result] = await this.$orm.query({
                    tags: {
                        order_by: { created_at: 'desc' }
                    }
				})
			
			if(err) {
				console.error('edit-link-modal', 'methods.getTags', '$http.request', err);
				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}

			this.tags = result
		},
		async deleteItem() {
			if(!confirm('Are you sure you\'d like to delete this link?')) return;

			this.loading = true;

			var [err, result] = await this.$orm.mutate({
                    links: {
                        update: {
                            where: { 
								id: { _eq: this.form.id }
							},
                            _set: { 
								is_deleted: true
							}
                        }
                    }
				})
				
			if (err) {
				this.loading = false;
				console.error('edit-link-modal', 'methods.deleteItem', '$http.request', err);
				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}

			this.loading = false;
			this.$emit('remove:form', this.form)

			this.resetForm();
            this.closeModal();
		}
	},
};
</script>