<template>
<div class="text-center">
    <div class="row">
        <div class="col-lg-9">
            <div class="form-group">
                <input class="form-control form-control-lg" type="text" id="redirect_link" v-model="form.link" @keyup.enter="submitForm()" placeholder="Paste your link here">
            </div>
        </div>
        <div class="col-lg-3">
            <div class="form-group">
                <button v-if="!loading" class="btn btn-block btn-outline-secondary btn-lg" type="submit" @click.prevent="submitForm()">
                    SHORTEN
                </button>
                <button v-else class="btn btn-block btn-outline-secondary btn-lg" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { to } from '@/plugins/utils'

export default {
    name: 'NewLinkForm',
    data: () => {
        return {
            Cform: {
                link: null,
                from_panel: 1
            },
            form: {},
            loading: false
        }
    },
    computed: {
        campaign() {
            return this.$route.params.campaign
        }
    },
    mounted() {
		this.resetForm();
	},
    methods: {
        resetForm() {
            this.form = Object.assign({}, this.Cform);
		},
        async submitForm() {
            if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

            if (!this.form.link)
                return $.NotificationApp.send('Error', 'Please enter a link', 'top-right', '', 'error');
            if (!this.campaign)
                return $.NotificationApp.send('Error', 'Unexpected error!', 'top-right', '', 'error');
                
            this.loading = true

            var [err, response] = await to( this.$http.post('/link/create', {
                ...this.form,
                campaign_id: this.campaign
            }) )

			if (err) {
				this.loading = false;
				console.error('form.add_link', 'methods.submitForm', '$http.request', err);
				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}
			if (response && response.data && response.data.error) {
				this.loading = false;
				console.error('form.add_link', 'methods.submitForm', '$http.response', response.data);
				return $.NotificationApp.send('Error', response.data.error, 'top-right', '', 'error');
			}
            this.loading = false;
            
            this.$emit('created')

			this.resetForm();
        }
    }
}
</script>

<style>

</style>